import { Provider } from "./shared";

export enum ConnectionStatus {
  OK = "OK",
  REQUIRES_AUTHENTICATION = "REQUIRES_AUTHENTICATION",
  REQUIRES_AUTHENTICATION_NOTIFIED = "REQUIRES_AUTHENTICATION_NOTIFIED",
  DISABLED = "DISABLED",
  FETCHING_INITIAL_DATA = "FETCHING_INITIAL_DATA",
}

export interface ConnectionResponse {
  id: number;
  sub: string;
  displayName: string;
  createdAt: string;
  createdBy: string;
  status: ConnectionStatus;
  provider: Provider;
}

export interface Connection {
  id: number;
  sub: string;
  providerId: string;
  displayName: string;
  createdAt: string;
  createdBy: string;
  status: ConnectionStatus;
  provider: Provider;
  providerDisplayName: string;
  displayStatus: string;
  logo: string;
  toggleActivateTooltip: string;
}

export const statusToDisplayStatus = {
  [ConnectionStatus.OK]: "Active",
  [ConnectionStatus.DISABLED]: "Disabled",
  [ConnectionStatus.REQUIRES_AUTHENTICATION]: "Requires Authentication",
  [ConnectionStatus.REQUIRES_AUTHENTICATION_NOTIFIED]:
    "Requires Authentication",
  [ConnectionStatus.FETCHING_INITIAL_DATA]: "Fetching data",
};

export const statusToActivateTooltip = {
  [ConnectionStatus.OK]: "Deactivate",
  [ConnectionStatus.DISABLED]: "Activate",
  [ConnectionStatus.REQUIRES_AUTHENTICATION]: "Click to reauthenticate",
  [ConnectionStatus.REQUIRES_AUTHENTICATION_NOTIFIED]:
    "Click to reauthenticate",
  [ConnectionStatus.FETCHING_INITIAL_DATA]: "Deactivate",
};

export type OrchestrationStatus =
  | "Running"
  | "Pending"
  | "Failed"
  | "Canceled"
  | "Terminated"
  | "Completed"
  | "Suspended";

export type OrchestrationStatusResponseSuccess = [
  { status: OrchestrationStatus; getStatusUrl: string },
  true,
];
export type OrchestrationStatusResponseFailure = [string | null, true | false];
export type OrchestrationStatusResponse =
  | OrchestrationStatusResponseSuccess
  | OrchestrationStatusResponseFailure;
