import { defineStore } from "pinia";
import {
  Connection,
  ConnectionResponse,
  OrchestrationStatus,
  OrchestrationStatusResponse,
} from "~/types/Connection";
import { Provider } from "~/types/shared";

export const CONNECTION_STORE = "connection-store";

export const useConnectionStore = defineStore({
  id: CONNECTION_STORE,
  state: () => {
    return {
      connections: [],
    } as {
      connections: Array<Connection>;
    };
  },
  actions: {
    async listConnections() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { connections: Array<Connection> };
      }>("connection");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedConnections } = useConnection();
        this.connections = getMappedConnections(data.value.data.connections);
      }
      return this.connections;
    },

    async createConnectionIntent(
      provider: string,
      reauthenticate: boolean,
      adAccountId?: number,
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { redirectUrl: string };
      }>("connection/create-intent", {
        method: "POST",
        body: { provider, reauthenticate, adAccountId },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        return data.value.data.redirectUrl;
      }
      return null;
    },

    async deleteConnection(connectionId: number) {
      const { error } = await useDatAdsApiFetch<null>(
        `connection/${connectionId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.connections = this.connections.filter(
        (connection) => connection.id !== connectionId,
      );
      return null;
    },

    async fetchAccountStructure(connectionId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { connection: ConnectionResponse };
      }>("/connection/fetch-account-structure", {
        method: "POST",
        body: { connectionId },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedConnections } = useConnection();
        const connection = getMappedConnections([
          data.value.data.connection,
        ])[0];
        this.connections = this.connections.map((conn) => {
          if (conn.id === connectionId) {
            return connection;
          }
          return conn;
        });
      }
      return null;
    },

    async triggerFetchInitialMetrics(
      dto: {
        accountId: number;
        connectionId: number;
      }[],
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          results: { status: OrchestrationStatus; getStatusUrl: string }[];
        };
      }>("/connection/trigger-fetch-initial-metrics", {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        return data.value.data.results;
      }
      return null;
    },

    async getJobStatus(
      getStatusUrl: string,
    ): Promise<OrchestrationStatusResponse> {
      const {
        public: { apiBaseUrl },
      } = useRuntimeConfig();
      const path = getStatusUrl.replace(apiBaseUrl, "");
      const { data, error } = await useDatAdsApiFetch<{
        data: { status: OrchestrationStatus; getStatusUrl: string };
      }>(path);
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        const shouldContinue =
          typeof error.value.statusCode === "number" &&
          error.value.statusCode >= 500;
        return [errorMessage, shouldContinue];
      }
      if (data.value) {
        return [data.value.data, true];
      }
      return [null, false];
    },
  },
  getters: {
    getConnectionById: (state) => (id: number) => {
      return state.connections.find((connection) => connection.id === id);
    },

    getConnectionsByProvider: (state) => (provider: Provider) => {
      return state.connections.filter(
        (connection) => connection.provider === provider,
      );
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConnectionStore, import.meta.hot));
}
